<template>
  <div class="full" style="background: #f8f8f8">
    <div class="full flex-column no-expand" style="position: absolute">
      <div class="pageTitle">
        <div
          style="left: 0.4rem; position: absolute; color: #fff"
          @click="goback"
        >
          <span><van-icon name="arrow-left" /></span>
        </div>
        <div class="title">订单列表</div>
      </div>
      <div style="overflow: scroll; height: 23rem">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="load_more"
          :offset="3"
        >
          <div v-for="item in itemList" :key="item.id">
            <van-cell style="background: #f8f8f8">
              <div>
                <span style="color: #b7b7b7">单号：{{ item.order_no }}</span>
                <div
                  style="
                    background: #fff;
                    min-height: 3.2rem;
                    border-radius: 0.2rem;
                  "
                >
                  <div style="padding: 0.5rem 0 0 0.5rem">
                    <span style="font-size: 0.8rem; color: #1b89fa">¥</span>
                    <span
                      style="color: #1b89fa; font-size: 1.5em; font-weight: 400"
                      >{{ formatPrice(item.pay_amount) }}</span
                    >
                  </div>
                  <div
                    style="
                      padding: 0.2rem 0 0 0.5rem;
                      font-size: 1em;
                      color: #a6a6a6;
                    "
                  >
                    <span>{{ item.name }}</span>
                  </div>
                  <div
                    style="
                      padding: 0rem 0 0.3rem 0.5rem;
                      font-size: 1em;
                      color: #a6a6a6;
                    "
                  >
                    <span>{{ item.create_time }}</span>
                  </div>
                </div>
              </div>
            </van-cell>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import {
  List,
  Cell,
  Checkbox,
  CheckboxGroup,
  Card,
  Pagination,
  Icon,
} from "vant";
import { selectOrderHistory } from "@/api/tableAndArea";
import { mapState } from "vuex";
export default {
  name: "MerchantAllOrder",
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [Pagination.name]: Pagination,
    [Icon.name]: Icon,
    [CheckboxGroup.name]: CheckboxGroup,
    [List.name]: List,
    [Cell.name]: Cell,
  },
  data() {
    return {
      loading: false,
      finished: false,
      itemList: [],
      currentPage: 1,
      pageSize: 5,
      total: 0.0,
    };
  },
  computed: {
    ...mapState({
      merId: (state) => state.base.merchantId,
    }),
  },
  created() {
    this.checkHistoryOrders();
  },
  watch: {
    $route(to) {
      if (to.name === "MerchantAllOrder") {
        this.itemList = [];
        this.checkHistoryOrders();
      }
    },
  },
  methods: {
    load_more: function () {
      this.currentPage++;
      this.checkHistoryOrders();
    },
    checkHistoryOrders() {
      selectOrderHistory(this.merId, 0, this.currentPage, this.pageSize)
        .then((res) => {
          let rows = res.page.content; //请求返回当页的列表
          this.loading = false;
          this.total = res.page.totalElements;
          if (rows == null || rows.length === 0) {
            this.finished = true;
            return;
          }
          this.itemList = this.itemList.concat(rows);
          if (this.itemList.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取历史订单失败，请稍后再试");
        });
    },
    formatPrice(price) {
      return price == undefined ? 0.0 : (price / 100).toFixed(2);
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.order-list {
  padding: 0.8rem 0.3rem 0.3rem 0.3rem;
  color: @mch-color;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-around;
  border-bottom: 0.03rem solid #eaeaea;
  box-shadow: 30rem 0 0 30rem #f8f8f8;
}
.title {
  color: #8d8d8d;
}
</style>
